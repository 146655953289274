@import 'src/styles.scss';

$font-size: 12px;
$font-color: #010101;
$font-weight: inherit;
$line-height: inherit;
$letter-spacing: inherit;

@font-face{
    font-family: Antonio-Bold;
    src: url('/assets/templates/template_005/fonts/Antonio-Bold.ttf');
}
@font-face{
    font-family: Antonio-Light;
    src: url('/assets/templates/template_005/fonts/Antonio-Light.ttf')
}
@font-face{
    font-family: Antonio-Regular;
    src : url('/assets/templates/template_005/fonts/Antonio-Regular.ttf');
}
@font-face{
    font-family: GOTHIC;
    src: url('/assets/templates/template_005/fonts/GOTHIC.TTF');
}
@font-face {
    font-family: GOTHICB;
    src: url('/assets/templates/template_005/fonts/GOTHICB.TTF');
}
@font-face {
    font-family: GOTHICBI;
    src: url('/assets/templates/template_005/fonts/GOTHICBI.TTF');
}
@font-face{
    font-family: GOTHICI;
    src: url('/assets/templates/template_005/fonts/GOTHICI.TTF');
}

#suggestion-container {
    width: 230px;
    border: 1px solid rgba(0,0,0,0.1) !important;
}
.search-panel i{
    display: none;
}
input:focus {
    outline: medium none !important;
}
#suggestion-container input{
    padding-left: 10px;
    margin: 0;
    margin-top: 4px;
    height: 30px;
    font-size: 12px !important;
    color: #7a7a7a !important;
    width: calc(100% - 10px);
}
#suggestion-container input::-webkit-input-placeholder{
    color: #7a7a7a;
}
.goPnl {
    width: 30px;
    height: 36px;
    text-align: right;
    cursor: pointer;
}
#lnkGo {
    line-height: 1.8;
    text-align: right;
    font-family: fontawesome !important;
}
#suggestion-container input:focus {
    border-bottom: 1px solid #e1e1e1 !important;
}
.blog-content-panel .content img {
    display: none ;
}
.search-panel app-search > div {
    height: 30px;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.2);
}
button:focus, a:focus {
    outline: medium none !important;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
perfect-scrollbar{
    margin-bottom: 20px !important;
    max-height: 240px !important;
    overflow: auto !important;
    height: auto !important;
}
.typeSpecFilterPanel perfect-scrollbar{
    overflow: auto !important;
    max-height: 145px !important;
}
#options-overlay {
    // width: 24% !important;
    width: 330px !important;
    max-height: 220px !important;
}
#options-overlay a {
    font-size: 12px;
    margin: 0;
    color: #7A7A7A;
    display: inline-block;
    width: 100%;
}
.waves-effect.option div {
    padding: 7px;
}
.waves-effect.option{
    width: 100% !important;
}
.waves-effect.option:hover{
    background-color: #DF695F !important;
}
.waves-effect.option div:hover{
    a{
        color: white !important;
    }
}
.loginPopUp .login-component{
    height: 435px;
    width: 770px;
    max-width: 770px !important;
    margin-bottom: 0 !important;
    border: 8px solid #DF695F;
}
// .loginPopUp .heading-lbl{
//     visibility: visible !important;
// }
body{
    font-family: Verdana, sans-serif; 
    min-height: 100%;
    height: auto !important;
    color: #333;
    font-size: 12px;
}
.mat-dialog-container{
    border: medium none !important;
    box-shadow: 4px 4px 4px rgba(0,0,0,0.7) !important;
    padding: 0 !important;
    overflow: initial !important;
    border-radius: 0 !important;
}
.hide{
    display: none !important;
    opacity: 0;
}
.rating-container{
    padding: 0px !important;
}
ls-suggestion{
    width: 330px !important;
    height: 30px !important;
    transition: all 0.4s cubic-bezier(0, 0.795, 0, 1) 0s;
    transform-origin: right center;
    i{
        display: none !important;
    }
}
.expand{
    // transform: scale(1);    
}
.collapse{
    // transform: scale(0);
    width: 0 !important;
}
a {
    color: #428bca;
    text-decoration: none;
    outline: medium none;
}
.static-page p {
    line-height: 1.9;
    font-size: 14px;
}
input.ng-invalid.ng-touched.ng-dirty {
    border: 1px solid #ff0000 !important;
}
.master{
    height: auto !important;
    position: relative;
}
.col-xs-12{
    width: 100%;
}
.cols-xs-1{
    width: 8.33%;
}
.cols-xs-2{
    width: 16.6%;
}
.cols-xs-3{
    width: 33.33%;
}
.cols-xs-4{
    width: 50%;
}
.no-padding{
    padding: 0;
}
.float-left{
    float: left;
}
.float-right{
    float: right;
}
.file-snip p{
    font-size: 14px;
}
.ps__rail-y {
    background-color: #aaa !important;
    width: 3px !important;
}

.ps__thumb-y {
    width: 4px !important;
    right: 0px !important;
    background-color: rgb(110, 171, 30) !important;
   
}
.static-page *{

}
select:focus{
    outline: none;
}
.defaultWidth{
    width: 990px;
    margin: 0 auto;
}
.panelClass{
    right: calc((50vw - 990px) + 450px);
}
.disable{
    opacity: 0.4;
    pointer-events: none;
}
@media screen and (min-width:1024px){
    .panelClass {
        position: absolute !important;
        // right: 10% !important;
        top: 40px;
    }
}
@media screen and (max-width: 1024px){
    .defaultWidth{
        width: 100%;
        margin: 0;
    }
    ls-suggestion{
        width: 95% !important;
    }
    .search-panel app-search > div{
        background-color: transparent;
    }
    app-search{
        width: 100%;
    }
    #options-overlay{
        width: 80% !important;
    }
    .menu-width {
        width: 70% !important;
        height: 100vh !important;
        background-color: transparent;
        box-shadow: none !important;
    }
    .panelClass {
        position: absolute !important;
        // right: 0% !important;
        top: 130px;
    }
    .static-page *{
       max-width: 100%;
       display: inline-block;
    }
}

@media screen and (max-width:767px){
    #options-overlay {
        width: 75% !important;
    }
    .static-page * {
        max-width: 100%;
        line-height: 1.2;
        display: inline-block;
        font-size: 1em !important;
    }
    .static-page img {
        width: 100% !important;
        height: auto;
    }
    #suggestion-container input,span#lnkGo{
        color: #a0a0a0 !important;
    }
    .cdk-global-overlay-wrapper{
        background-color: rgba(0,0,0,0.7) !important;
    }
    app-order-tracking {
        padding: 0 20px;
        display: inline-block;
        width: 100%;
    }
    .mce-panel {
        width: 100% !important;
    }
    ._2p3a{
        min-width: 100% !important;
    }
    .static-page p{
        line-height: 1.5;
    }
    .typeSpecFilterPanel perfect-scrollbar{
        margin-bottom: 0 !important
    }
    .menu-width {
        width: 80% !important;
        height: 78vh !important;
        background-color: transparent;
        box-shadow: none !important;
    }
    .panelClass{
        position: static !important;
    }
}
@media screen and (min-width :1024px) and (max-width:1280px){
    .panelClass{
        // right: 0 !important;
    }
}